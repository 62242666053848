import React from 'react';
import { Box, Container, Theme, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ROOT_ROUTES } from '../../Constants/routes';
import Link from 'next/link';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(3),
        paddingTop: 80,
        paddingBottom: 80
    },
}));

const NotFound404 = (): JSX.Element => {
    const { classes } = useStyles();
    const { t } = useTranslation('common');

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <Typography
                    align="center"
                    variant='h1'
                    color="textPrimary"
                >
                    {t('NOT_FOUND')}
                </Typography>
                <Typography
                    align="center"
                    variant="subtitle2"
                >
                    {t('REMOVED_OR_DNE')}
                </Typography>
                <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                >
                    <Link href={ROOT_ROUTES.HOME}>
                        <Button
                            aria-label='back to home'
                            color="primary"
                            variant="outlined"
                        >
                            {t('BACK_TO_HOME')}
                        </Button>
                    </Link>
                </Box>
            </Container>
        </div>
    );
};

export default NotFound404;